import "./App.css";
import { Route, Routes } from "react-router-dom";
import { Layout, Home, ContactUs, Product, AboutUs } from "./Pages";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index path="/" element={<Home />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="product" element={<Product />} />
          <Route path="contact-us" element={<ContactUs />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;

import React, { useState } from "react";
import { ContactLeftImg, HomeContactBG, VideoBG } from "../Images";
import { Link } from "react-router-dom";
import { ValidEmail } from "../Components/Helper";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import VisibleError from "../Components/VisibleError/VisibleError";
import { TailSpin } from "react-loader-spinner";

const ContactUs = () => {
  const [loader, setLoader] = useState(false);
  const [validemail, setValidemail] = useState(false);
  const [fromDataAll, setFromDataAll] = useState({
    name: "",
    email: "",
    message: "",
    phNumber: "",
    Subject: "",
  });

  const [fromDataError, setFromDataError] = useState({
    name: false,
    email: false,
    message: false,
    phNumber: false,
    Subject: false,
  });

  const sendEmail = async (e) => {
    e.preventDefault();
    let check = false;
    if (fromDataAll.name === "") {
      setFromDataError((pre) => {
        return {
          ...pre,
          name: true,
        };
      });
      check = true;
    }
    if (fromDataAll.email === "") {
      setFromDataError((pre) => {
        return {
          ...pre,
          email: true,
        };
      });
      check = true;
    } else if (!ValidEmail(fromDataAll.email)) {
      setValidemail(true);
      check = true;
    }
    if (fromDataAll.phNumber === "") {
      setFromDataError((pre) => {
        return {
          ...pre,
          phNumber: true,
        };
      });
      check = true;
    }
    if (fromDataAll.Subject === "") {
      setFromDataError((pre) => {
        return {
          ...pre,
          Subject: true,
        };
      });
      check = true;
    }
    if (fromDataAll.message === "") {
      setFromDataError((pre) => {
        return {
          ...pre,
          message: true,
        };
      });
      check = true;
    }
    if (check) return;

    setLoader(true);

    try {
      const payloade = {
        userName: fromDataAll.name,
        email: fromDataAll.email,
        message: fromDataAll.message,
        phNumber: fromDataAll.phNumber,
        subject: fromDataAll.Subject,
      };
      await emailjs
        .send(
          "service_l19ojj8",
          "template_itao5ad",
          payloade,
          "xRc6LTmLrpupjzZj0"
        )
        .then(
          (response) => {
            if (response?.status === 200) {
              setFromDataAll({
                name: "",
                email: "",
                message: "",
                phNumber: "",
                Subject: "",
              });
              toast.success("Form Submitted Successfully!");
            } else {
              toast.error(response);
            }
          },
          (error) => {
            console.log("FAILED... ", error);
            toast.error(error);
          }
        );
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };

  const handelechange = (e, main = 11) => {
    let { name, value } = e.target;
    if (name === "phNumber") {
      value = value.slice(0, main);
    }
    setFromDataAll((pre) => {
      return {
        ...pre,
        [name]: value,
      };
    });
    setFromDataError((pre) => {
      return {
        ...pre,
        [name]: false,
      };
    });
    setValidemail(false);
  };

  return (
    <>
      <div
        className="site-breadcrumb"
        style={{ backgroundImage: `url(${VideoBG})` }}
      >
        <div className="container">
          <h2 className="breadcrumb-title">Contact Us</h2>
          <ul className="breadcrumb-menu">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>/</li>
            <li className="active">Contact Us</li>
          </ul>
        </div>
      </div>
      <div className="contact-area py-120">
        <div className="container">
          <div className="contact-content">
            <div className="row">
              <div className="col-md-4">
                <div className="contact-info">
                  <div className="contact-info-icon">
                    <i className="fa fa-map-location-dot"></i>
                  </div>
                  <div className="contact-info-content">
                    <h5>Office Address</h5>
                    <p>19, Pollock Street, Kolkata</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="contact-info">
                  <div className="contact-info-icon">
                    <i className="fa fa-phone-volume"></i>
                  </div>
                  <div className="contact-info-content">
                    <h5>Call Us</h5>
                    <p>+91 9073063500</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="contact-info">
                  <div className="contact-info-icon">
                    <i className="fa fa-envelope"></i>
                  </div>
                  <div className="contact-info-content">
                    <h5>Email Us</h5>
                    <p>kolkata.essex@gmail.com</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="contact-wrapper">
            <div className="row g-4">
              <div className="col-lg-5">
                <div className="contact-img">
                  <img src={ContactLeftImg} alt="" />
                </div>
              </div>
              <div className="col-lg-7">
                <div className="contact-form">
                  <div className="contact-form-header">
                    <h2>Get In Touch</h2>
                    <p>
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page randomised
                      words which don't look even slightly when looking at its
                      layout.{" "}
                    </p>
                  </div>
                  <form id="contact-form">
                    <div className="row">
                      <div className="col-md-6 mb-2">
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className="fa fa-user-tie"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            placeholder="Your Name"
                            required
                            onChange={handelechange}
                            value={fromDataAll.name}
                          />
                        </div>
                        <VisibleError
                          show={fromDataError.name}
                          name="full name"
                        />
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className="fa fa-envelope"></i>
                          </span>
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            placeholder="Your Email"
                            required
                            onChange={handelechange}
                            value={fromDataAll.email}
                          />
                        </div>
                        {validemail && (
                          <span
                            style={{
                              color: "red",
                              fontSize: 14,
                            }}
                          >
                            Please enter a valid email !
                          </span>
                        )}
                        <VisibleError
                          show={fromDataError.email}
                          name="e-mail"
                        />
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className="fa-solid fa-phone"></i>
                          </span>
                          <input
                            type="number"
                            name="phNumber"
                            className="form-control"
                            placeholder="Contact Number"
                            required
                            onChange={(e) => handelechange(e, 20)}
                            value={fromDataAll.phNumber}
                          />
                        </div>
                        <VisibleError
                          show={fromDataError.phNumber}
                          name="phone no"
                        />
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className="fa fa-pen"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            name="Subject"
                            placeholder="Your Subject"
                            required
                            onChange={handelechange}
                            value={fromDataAll.Subject}
                          />
                        </div>
                        <VisibleError
                          show={fromDataError.Subject}
                          name="your subject"
                        />
                      </div>
                      <div className="colmd-12 mb-2">
                        <div className="input-group textarea">
                          <span className="input-group-text">
                            <i className="fa fa-comment"></i>
                          </span>
                          <textarea
                            name="message"
                            cols="30"
                            rows="5"
                            className="form-control"
                            placeholder="Write Your Message"
                            onChange={handelechange}
                            value={fromDataAll.message}
                          ></textarea>
                        </div>
                        <VisibleError
                          show={fromDataError.message}
                          name="message"
                        />
                      </div>
                    </div>

                    <div>
                      <button
                        type="submit"
                        className="theme-btn"
                        disabled={loader}
                        onClick={sendEmail}
                      >
                        {loader ? (
                          <TailSpin
                            visible={true}
                            height="20"
                            width="50"
                            color="#fff"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                          />
                        ) : (
                          <>
                            Send Message <i className="far fa-paper-plane"></i>
                          </>
                        )}
                      </button>
                    </div>
                    <div className="col-md-12 mt-3">
                      <div className="form-messege text-success"></div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-area pb-5">
            <div className="container">
                <div className="row g-4">
                    <div className="col-md-6 col-lg-6">
                        <div className="blog-item">
                            <div className="blog-item-img">
                            <iframe title="Unit-1" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3707.945717321659!2d87.57413969999999!3d21.6659689!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a032d3e28b9620f%3A0x16e2636408c92c68!2sESSEX%20MARINE%20PVT.%20LTD.!5e0!3m2!1sen!2sin!4v1733932688134!5m2!1sen!2sin" width="100%" height="300" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                            <div className="blog-item-info">
                                <div className="blog-item-meta">
                                    <ul>
                                        <li><i className="fa fa-location"></i> Unit - 1</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6">
                        <div className="blog-item">
                            <div className="blog-item-img">
                            <iframe title="Unit-2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3684.5869870747824!2d88.39436769999999!3d22.557139199999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a027719deb20b13%3A0x5e1158152184679a!2sESSEX%20MARINE%20PVT.%20LTD.!5e0!3m2!1sen!2sin!4v1733932855519!5m2!1sen!2sin" width="100%" height="300" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                            <div className="blog-item-info">
                                <div className="blog-item-meta">
                                    <ul>
                                        <li><i className="fa fa-location"></i> Unit - 2</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      {/* <div className="contact-map">
        <iframe
          title="Essex Marine Private Limited"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3684.071019110014!2d88.35021871020916!3d22.57644697940347!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a0277baa8a9adad%3A0x7de19f1a4ae8cb9e!2sEssex%20Marine%20Private%20Limited!5e0!3m2!1sen!2sin!4v1732809828621!5m2!1sen!2sin"
          style={{ border: "0px" }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div> */}
      <div
        className="cta-area"
        style={{ backgroundImage: `url(${HomeContactBG})` }}
      >
        <div className="container">
          <div className="row">
            <div className="cta-content">
              <h1>Contact Us</h1>
              <p>
                19, POLLOCK STREET, 7TH FLOOR, ROOM NO:7 KOLKATA - 700001
                <br /> <Link to="tel:03322627928">03322627928</Link>{" "}
                <Link to="tel:+91 9073063500">+91 9073063500</Link>{" "}
                <Link to="mailto:kolkata.essex@gmail.com">
                  kolkata.essex@gmail.com
                </Link>
              </p>
              <a href="/contact-us" className="theme-btn">
                Contact Now<i className="fas fa-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;

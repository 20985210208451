import React from "react";
import {
  HomeContactBG,
  ProductEight,
  ProductFive,
  ProductFour,
  ProductNine,
  ProductOne,
  ProductSeven,
  ProductSix,
  ProductThree,
  ProductTwo,
  VideoBG,
} from "../Images";
import { Link } from "react-router-dom";

const Product = () => {
  return (
    <>
      <div
        className="site-breadcrumb"
        style={{ backgroundImage: `url(${VideoBG})` }}
      >
        <div className="container">
          <h2 className="breadcrumb-title">Our Product</h2>
          <ul className="breadcrumb-menu">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>/</li>
            <li className="active">Our Product</li>
          </ul>
        </div>
      </div>
      <div className="product-area py-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-5">
              <div className="portfolio-item">
                <div className="portfolio-img">
                  <img className="img-fluid" src={ProductOne} alt="" />
                  <Link className="popup-img portfolio-link" to="/product">
                    <i className="fa fa-plus"></i>
                  </Link>
                </div>
                <div className="portfolio-content">
                  <div className="portfolio-content-info">
                    <small>FROZEN EEL</small>
                  </div>
                  <Link to="/product" className="portfolio-arrow">
                    <i className="fa fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-5">
              <div className="portfolio-item">
                <div className="portfolio-img">
                  <img className="img-fluid" src={ProductTwo} alt="" />
                  <Link className="popup-img portfolio-link" to="/product">
                    <i className="fa fa-plus"></i>
                  </Link>
                </div>
                <div className="portfolio-content">
                  <div className="portfolio-content-info">
                    <small>WHOLE CLEAN SQUID</small>
                  </div>
                  <Link to="/product" className="portfolio-arrow">
                    <i className="fa fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-5">
              <div className="portfolio-item">
                <div className="portfolio-img">
                  <img className="img-fluid" src={ProductThree} alt="" />
                  <Link className="popup-img portfolio-link" to="/product">
                    <i className="fa fa-plus"></i>
                  </Link>
                </div>
                <div className="portfolio-content">
                  <div className="portfolio-content-info">
                    <small>SEA CAUGHT SHRIMP</small>
                  </div>
                  <Link to="/product" className="portfolio-arrow">
                    <i className="fa fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-5">
              <div className="portfolio-item">
                <div className="portfolio-img">
                  <img className="img-fluid" src={ProductFour} alt="" />
                  <Link className="popup-img portfolio-link" to="/product">
                    <i className="fa fa-plus"></i>
                  </Link>
                </div>
                <div className="portfolio-content">
                  <div className="portfolio-content-info">
                    <small>SEA CAUGHT SHRIMP</small>
                  </div>
                  <Link to="/product" className="portfolio-arrow">
                    <i className="fa fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-5">
              <div className="portfolio-item">
                <div className="portfolio-img">
                  <img className="img-fluid" src={ProductFive} alt="" />
                  <Link className="popup-img portfolio-link" to="/product">
                    <i className="fa fa-plus"></i>
                  </Link>
                </div>
                <div className="portfolio-content">
                  <div className="portfolio-content-info">
                    <small>FROZEN SOLE FISH</small>
                  </div>
                  <Link to="/product" className="portfolio-arrow">
                    <i className="fa fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-5">
              <div className="portfolio-item">
                <div className="portfolio-img">
                  <img className="img-fluid" src={ProductSix} alt="" />
                  <Link className="popup-img portfolio-link" to="/product">
                    <i className="fa fa-plus"></i>
                  </Link>
                </div>
                <div className="portfolio-content">
                  <div className="portfolio-content-info">
                    <small>FROZEN SLAB</small>
                  </div>
                  <Link to="/product" className="portfolio-arrow">
                    <i className="fa fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="portfolio-item">
                <div className="portfolio-img">
                  <img className="img-fluid" src={ProductSeven} alt="" />
                  <Link className="popup-img portfolio-link" to="/product">
                    <i className="fa fa-plus"></i>
                  </Link>
                </div>
                <div className="portfolio-content">
                  <div className="portfolio-content-info">
                    <small>FROZEN POMFRET</small>
                  </div>
                  <Link to="/product" className="portfolio-arrow">
                    <i className="fa fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="portfolio-item">
                <div className="portfolio-img">
                  <img className="img-fluid" src={ProductEight} alt="" />
                  <Link className="popup-img portfolio-link" to="/product">
                    <i className="fa fa-plus"></i>
                  </Link>
                </div>
                <div className="portfolio-content">
                  <div className="portfolio-content-info">
                    <small>POMFRET</small>
                  </div>
                  <Link to="/product" className="portfolio-arrow">
                    <i className="fa fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="portfolio-item">
                <div className="portfolio-img">
                  <img className="img-fluid" src={ProductNine} alt="" />
                  <Link className="popup-img portfolio-link" to="/product">
                    <i className="fa fa-plus"></i>
                  </Link>
                </div>
                <div className="portfolio-content">
                  <div className="portfolio-content-info">
                    <small>BLANCHED VANNAMEI</small>
                  </div>
                  <Link to="/product" className="portfolio-arrow">
                    <i className="fa fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="cta-area"
        style={{ backgroundImage: `url(${HomeContactBG})` }}
      >
        <div className="container">
          <div className="row">
            <div className="cta-content">
              <h1>Contact Us</h1>
              <p>
                19, POLLOCK STREET, 7TH FLOOR, ROOM NO:7 KOLKATA - 700001
                <br /> <Link to="tel:03322627928">03322627928</Link>{" "}
                <Link to="tel:+91 9073063500">+91 9073063500</Link>{" "}
                <Link to="mailto:kolkata.essex@gmail.com">
                  kolkata.essex@gmail.com
                </Link>
              </p>
              <a href="/contact-us" className="theme-btn">
                Contact Now<i className="fas fa-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;

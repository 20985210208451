import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <>
    <footer className="footer-area">
        <div className="copyright">
          <div className="container">
            <div className="row">
              <div className="col-md-6 align-self-center">
                <p className="copyright-text">
                  &copy; Copyright <span id="date"></span>{" "}
                  <Link to="/"> Essex </Link> All Rights Reserved.
                </p>
              </div>
              <div className="col-md-6 align-self-center">
                <ul className="footer-social">
                  <li>
                    <Link to="https://www.facebook.com/share/jdBXFgTRM1QTdmxF/"><i className="fab fa-facebook"></i></Link>
                  </li>
                  {/* <li>
                  <Link to="/"><i className="fab fa-x-twitter"></i></Link>
                  </li> */}
                  <li>
                  <Link to="https://www.instagram.com/essex.marine/profilecard/?igsh=MWx5dGZvOGw5enFubQ=="><i className="fab fa-instagram"></i></Link>
                  </li>
                  {/* <li>
                  <Link to="/"><i className="fab fa-linkedin"></i></Link>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
import React from "react";

const VisibleError = ({ show, name = "value" }) => {
  return (
    <span
      style={{
        visibility: show ? "visible" : "hidden",
        fontSize: "13px",
        color: "red",
        paddingLeft: "10px",
        textAlign: "center",
      }}
    >
      {`Please enter ${name}`}
    </span>
  );
};

export default VisibleError;



import React from "react";
import { Link } from "react-router-dom";
import {
  AboutThree,
  AboutTwoImg,
  BRC,
  EIC,
  FFSC,
  FSSAI,
  HACCP,
  HomeContactBG,
  MPEDA,
  VideoBG,
} from "../Images";
// import PdfLoader from "../Components/Pdf/PdfLoader";
import {
  MPEDA_Processing_Plant,
  HACCP_Certificate,
  FSSC_Certificate,
  FSSAI_Shankarpur,
  EIC_CERTIFICATES,
  BRC_Certificate,
} from "../Components/PdfDocument";
import CountUp from "react-countup";

const AboutUs = () => {
  // const all_pdf_File = [
  //   MPEDA_Processing_Plant,
  //   HACCP_Certificate,
  //   FSSC_Certificate,
  //   FSSAI_Shankarpur,
  //   EIC_CERTIFICATES,
  //   BRC_Certificate,
  // ];

  // const [currentPdf, setCurrentPdf] = useState(all_pdf_File[0]);
  return (
    <>
      <div
        className="site-breadcrumb"
        style={{ backgroundImage: `url(${VideoBG})` }}
      >
        <div className="container">
          <h2 className="breadcrumb-title">About Us</h2>
          <ul className="breadcrumb-menu">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>/</li>
            <li className="active">About Us</li>
          </ul>
        </div>
      </div>

      <div className="about-area py-120 mb-40">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-left">
                <div className="about-img">
                  <img className="about-img-1" src={AboutThree} alt="" />
                  <img className="about-img-2" src={AboutTwoImg} alt="" />
                </div>
                <div className="about-experience">
                  <span>25</span>
                  <h5>
                    Years Of <br /> Experience
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-right">
                <div className="site-heading mb-3">
                  <span className="site-title-tagline">About Us</span>
                  <h2 className="site-title">
                    Explore Your <span>Exciting Fishing</span> World With Us.
                  </h2>
                </div>
                <p className="about-text">
                  Essex Marine Pvt Ltd, Midnapur, India is a modern seafood
                  processing and exporting company, promoted by Sri. Debashish
                  Sen, the Managing Director. The Registered office of the
                  company is at 19, Pollock Street, Kolkata 700 001, WB. The
                  objective of the company is to procure, process, store and
                  export their safe and quality fish and aquaculture products to
                  all the countries including European Union. The company is
                  designed with all modern facilities in line with
                  specifications of national and importing countries.
                </p>
                <p className="about-text">
                  The Company is installed with the state-of-the-art machineries
                  and equipments. Our processing and Freezing capacity.
                </p>
                <p className="about-text">
                  The company is constructed in an ideal unpolluted location
                  having an advantages of availability of drinking water,
                  skilled manpower, required electricity, uninterrupted
                  communication system, near by port connected with wide NH-
                  highways, abundance of wholesome raw materials and other
                  in-puts.
                </p>
                <p className="about-text">
                  We also have a state-of-the-art Cold Storage facility in the
                  heart of Kolkata within the Udayan Industrial Estate. This
                  facility, comprising multiple cold room chambers, serves both
                  our domestic sales and external clints with frozen product
                  storage. Due to its prime location, our cold storage facility
                  consistently operates at full capacity. The entire operations
                  are managed by a team of dedicated, experienced, and trained
                  work force the able guidance of supervisors, divisional heads
                  and General Manager.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="site-heading text-center wow fadeInDown"
        data-wow-delay=".25s"
        style={{
          visibility: "visible",
          animationDelay: "0.25s",
          animationName: "fadeInDown",
        }}
      >
        <span className="site-title-tagline">
          Under the Brand Of - DE SENS COLD STORE
        </span>
        <h2 className="site-title">
          <img
            decoding="async"
            srcSet="https://essexmpl.com/wp-content/uploads/2024/10/logo-e1729526469287.png ,https://essexmpl.com/wp-content/uploads/2024/10/logo-e1729526469287.png 780w, https://essexmpl.com/wp-content/uploads/2024/10/logo-e1729526469287.png 360w"
            sizes="(max-width: 480px) 150px"
            src="https://essexmpl.com/wp-content/uploads/2024/10/logo-e1729526469287.png"
            alt="Logo"
            className="uag-image-1358"
            width="200"
            height="122"
            title="logo200"
            loading="lazy"
          />
        </h2>
      </div>
      <div
        className="site-heading text-center wow fadeInDown"
        data-wow-delay=".25s"
        style={{
          visibility: "visible",
          animationDelay: "0.25s",
          animationName: "fadeInDown",
        }}
      >
        {/* <span className="site-title-tagline">Destination</span> */}
        <h2 className="site-title">
          We have one of the highest <b />
          <span>production capacity in India</span>
        </h2>
        <div className="heading-divider"></div>
      </div>
      <div className="counter-area bg2 pt-70 pb-70">
        <div className="row g-4 justify-content-center">
          <div className="col-md-12 col-lg-6 col-xl-4">
            <div className="counter-box">
              <div className="icon">
                {/* <img src={fish} alt="" /> */}
                <i className="fa-solid fa-shrimp"></i>
              </div>
              <div>
                <span
                  className="counter"
                  data-count="+"
                  data-to="120"
                  data-speed="3000"
                >
                  <CountUp scrollSpyOnce start={0} duration={10} end={17155} />{" "}
                  <span
                    style={{
                      fontSize: 28,
                    }}
                  >
                    MT./ Annum
                  </span>
                </span>
                <h6 className="title">For Shrimp </h6>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6 col-xl-4">
            <div className="counter-box">
              <div className="icon">
                {/* <img src={"https://live.themewild.com/fishred/assets/img/icon/fishing-2.svg"} alt="" /> */}
                {/* <img
                  src={
                    "https://live.themewild.com/fishred/assets/img/icon/fishing-5.svg"
                  }
                  alt=""
                /> */}
                <i className="fa-solid fa-fish"></i>
              </div>
              <div>
                <span
                  className="counter"
                  data-count="+"
                  data-to="50"
                  data-speed="3000"
                >
                  <CountUp scrollSpyOnce start={0} duration={10} end={12775} />{" "}
                  <span
                    style={{
                      fontSize: 28,
                    }}
                  >
                    MT./ Annum
                  </span>
                </span>
                <h6 className="title">For Fish</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="process-area bg py-80">
        <div className="container">
          <div className="row">
            {/* <div className="col-sm-4">
              <div className="left_radius">
                <h3 className="mb-0">
                  Join the <br /> Future. <br /> <span>Right Now</span>
                </h3>
              </div>
            </div>
            <div className="col-sm-8">
              <div className="about_right_sec">
                <h3 className="mb-1">Scale 1 Officers (Assistant Manager)</h3>
                <h4>Positions: 110 Officers</h4>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Voluptas inventore doloribus ab veniam necessitatibus
                  recusandae aliquid quidem autem alias sunt. Modi adipisci quam
                  dolores vitae laudantium ex nobis rem amet? Lorem ipsum, dolor
                  sit amet consectetur adipisicing elit. Voluptas inventore
                  doloribus ab veniam necessitatibus recusandae aliquid quidem
                  autem alias sunt. Modi adipisci quam dolores vitae laudantium
                  ex nobis rem amet?Lorem ipsum, dolor sit amet consectetur
                  adipisicing elit. Voluptas inventore doloribus ab veniam
                  necessitatibus recusandae aliquid quidem autem alias sunt.
                  Modi adipisci quam dolores vitae laudantium ex nobis rem amet?
                </p>
              </div>
            </div> */}
            <div className="col-sm-12">
              {/* <div className="main_blue_heading mb-4">
                <p className="mb-0">
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iure
                  reprehenderit voluptate ullam
                </p>
              </div> */}
            </div>
            <div className="col-sm-12">
              <div className="main_sec mb-3">
                <div className="blue_area">
                  <h3>
                    Minimum <br />
                    Capacity
                  </h3>
                </div>
                <div className="white_area">
                  <p className="mb-0">
                    ⁠IQF with Glazer and Hardener : 900 kg/hr with an approved
                    capacity of 14.00 tons/day.
                  </p>
                </div>
              </div>
              <div className="main_sec mb-3">
                <div className="blue_area">
                  <h3>
                    Capacity <br />
                    Per Day
                  </h3>
                </div>
                <div className="white_area">
                  <p className="mb-0">
                    2 Plate Freezers with each capacity of 1000 kg/batch and 2
                    Plate Freezer with each capacity 600 kg/batch with a total
                    approved capacity of 33 Tons/day.
                  </p>
                </div>
              </div>
              <div className="main_sec mb-3">
                <div className="blue_area">
                  <h3>
                    Modern <br />
                    Racking
                  </h3>
                </div>
                <div className="white_area">
                  <p className="mb-0">
                    ⁠2 Blast freezers with a combined approved capacity of 35
                    Tons/day. 3 Cold storage chambers with modern racking system
                    having a total storing capacity of 720 Tons.
                  </p>
                </div>
              </div>
              <div className="main_sec mb-3">
                <div className="blue_area">
                  <h3>
                    Modern <br />
                    Water System
                  </h3>
                </div>
                <div className="white_area">
                  <p className="mb-0">
                    Ultra modern water treatment plant is installed with RO and
                    UV systems. Water and ice is obtained with the support of
                    the said WTP. The factory is implemented with GMP, SSOP,
                    HACCP, FSSAI and BRC
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-80">
        <div
          className="site-heading text-center wow fadeInDown"
          data-wow-delay=".25s"
          style={{
            visibility: "visible",
            animationDelay: "0.25s",
            animationName: "fadeInDown",
          }}
        >
          {/* <span className="site-title-tagline">Destination</span> */}
          <h2 className="site-title">
            Our <span>Certificates</span>
          </h2>
          <div className="heading-divider"></div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="team-item">
                <Link to={BRC_Certificate} target="_blank">
                  <img src={BRC} alt="thumb" />
                  <div className="team-content">
                    <div className="team-bio">
                      <h5>
                        <a href="team.html">BRC Certificate</a>
                      </h5>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="team-item">
                <Link to={EIC_CERTIFICATES} target="_blank">
                  <img src={EIC} alt="thumb" />
                  <div className="team-content">
                    <div className="team-bio">
                      <h5>
                        <a href="team.html">EIC Certificate</a>
                      </h5>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="team-item">
                <Link to={FSSAI_Shankarpur} target="_blank">
                  <img src={FSSAI} alt="thumb" />
                  <div className="team-content">
                    <div className="team-bio">
                      <h5>
                        <a href="team.html">FSSAI</a>
                      </h5>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="team-item">
                <Link to={FSSC_Certificate} target="_blank">
                  <img src={FFSC} alt="thumb" />
                  <div className="team-content">
                    <div className="team-bio">
                      <h5>
                        <a href="team.html">FSSC Certificate</a>
                      </h5>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="team-item">
                <Link to={HACCP_Certificate} target="_blank">
                  <img src={HACCP} alt="thumb" />
                  <div className="team-content">
                    <div className="team-bio">
                      <h5>
                        <a href="team.html">HACCP Certificate</a>
                      </h5>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="team-item">
                <Link to={MPEDA_Processing_Plant} target="_blank">
                  <img src={MPEDA} alt="thumb" />
                  <div className="team-content">
                    <div className="team-bio">
                      <h5>
                        <a href="team.html">MPEDA Processing Plant</a>
                      </h5>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="cta-area"
        style={{ backgroundImage: `url(${HomeContactBG})` }}
      >
        <div className="container">
          <div className="row">
            <div className="cta-content">
              <h1>Contact Us</h1>
              <p>
                19, POLLOCK STREET, 7TH FLOOR, ROOM NO:7 KOLKATA - 700001
                <br /> <Link to="tel:03322627928">03322627928</Link>{" "}
                <Link to="tel:+91 9073063500">+91 9073063500</Link>{" "}
                <Link to="mailto:kolkata.essex@gmail.com">
                  kolkata.essex@gmail.com
                </Link>
              </p>
              <a href="/contact-us" className="theme-btn">
                Contact Now<i className="fas fa-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="pdf_editor py-80">
        <div className="prospectus-structure">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-sm-10">
                <PdfLoader allPdfFiles={all_pdf_File} />
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default AboutUs;

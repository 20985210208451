import React from "react";
import { Link } from "react-router-dom";
import { Logo } from "../Images";

const Header = () => {
  return (
    <>
      <header className="header">
        <div className="header-top">
          <div className="container">
            <div className="header-top-wrapper">
              <div className="header-top-left">
                <div className="header-top-contact">
                  <ul>
                    <li>
                      <Link href="mailto:kolkata.essex@gmail.com">
                        <i className="fa fa-envelope"></i>
                        kolkata.essex@gmail.com
                      </Link>
                    </li>
                    <li>
                      <Link to="mailto:+91 9073063500">
                        <i className="fa fa-phone-volume"></i>+91 9073063500
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="header-top-right">
                <div className="header-top-social">
                  <span>Follow Us: </span>
                  <Link to="https://www.facebook.com/share/jdBXFgTRM1QTdmxF/">
                    <i className="fab fa-facebook"></i>
                  </Link>
                  {/* <Link to="/">
                    <i className="fab fa-x-twitter"></i>
                  </Link> */}
                  <Link to="https://www.instagram.com/essex.marine/profilecard/?igsh=MWx5dGZvOGw5enFubQ==">
                    <i className="fab fa-instagram"></i>
                  </Link>
                  {/* <Link to="/">
                    <i className="fab fa-linkedin"></i>
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-navigation">
          <nav className="navbar navbar-expand-lg">
            <div className="container position-relative">
              <Link className="navbar-brand" to="/">
                <img src={Logo} alt="logo" />
              </Link>
              <div className="mobile-menu-right">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#main_nav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-mobile-icon">
                    {/* <i className="far fa-bars"></i> */}
                    <i className="fa-solid fa-bars"></i>
                  </span>
                </button>
              </div>
              <div className="collapse navbar-collapse" id="main_nav">
                <ul className="navbar-nav">
                  {/* <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">Blog</a>
                                <ul className="dropdown-menu fade-down">
                                    <li><a className="dropdown-item" href="blog.html">Blog</a></li>
                                    <li><a className="dropdown-item" href="blog-single.html">Blog Single</a></li>
                                </ul>
                            </li> */}
                  <li className="nav-item">
                    <Link className="nav-link" to="/">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/about-us">
                      About Us
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/product">
                      Product
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/contact-us">
                      Contact Us
                    </Link>
                  </li>
                </ul>
                <div className="nav-right">
                  <div className="nav-right-btn">
                    <Link to="/contact-us" className="theme-btn">
                      Get Quote<i className="fas fa-arrow-right"></i>
                    </Link>
                  </div>
                  {/* <div className="sidebar-btn">
                                <button type="button" className="nav-right-link"><i className="fa fa-bars"></i></button>
                            </div> */}
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
      <div className="sidebar-popup">
        <div className="sidebar-wrapper">
          <div className="sidebar-content">
            <button type="button" className="close-sidebar-popup">
              <i className="far fa-xmark"></i>
            </button>
            <div className="sidebar-logo">
              <img src="assets/img/logo/logo.png" alt="" />
            </div>
            <div className="sidebar-about">
              <h4>About Us</h4>
              <p>
                There are many variations of passages available sure there
                majority have suffered alteration in some form by inject humour
                or randomised words which don't look even slightly believable.
              </p>
            </div>
            <div className="sidebar-contact">
              <h4>Contact Info</h4>
              <ul>
                <li>
                  <h6>Email</h6>
                  <Link to="mailto:kolkata.essex@gmail.com">
                    <i className="fa fa-envelope"></i>kolkata.essex@gmail.com
                  </Link>
                </li>
                <li>
                  <h6>Phone</h6>
                  <Link to="tel:+91 9073063500">
                    <i className="far fa-phone"></i>+91 9073063500
                  </Link>
                </li>
                <li>
                  <h6>Address</h6>
                  <i className="far fa-location-dot"></i>25/B Milford Road, New
                  York
                </li>
              </ul>
            </div>
            <div className="sidebar-social">
              <h4>Follow Us</h4>
              <Link to="/">
                <i className="fab fa-facebook"></i>
              </Link>
              <Link to="/">
                <i className="fab fa-x-twitter"></i>
              </Link>
              <Link to="/">
                <i className="fab fa-instagram"></i>
              </Link>
              <Link to="/">
                <i className="fab fa-linkedin"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
